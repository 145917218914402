<template>
  <header class="header">
    <div class="header_logo">
      <router-link to="/">
        <img src="../assets/images/logo_header.png" alt="ロゴ" />
      </router-link>
    </div>
    <nav>
      <div class="menu-button" :class="{ open: menuOpen }" @click="menuOpen = !menuOpen">
        <span class="menu-button_bar"></span>
        <span class="menu-button_bar"></span>
        <span class="menu-button_bar"></span>
      </div>
      <transition name="fade">
        <nav v-show="menuOpen" class="nav" :class="{ buttonShow }">
          <ul class="nav_list">
            <li v-for="item in items" :key="item.id">
              <router-link :to="item.url" @click="btnClick" class="nav_list_item">{{ item.name }}</router-link>
            </li>
          </ul>
          <div class="sns">
            <p class="sns_text">各種SNSチェックもお願いします！</p>
            <div class="sns_flex">
              <a href="https://lin.ee/Zns71uO" class="sns_icon" target="_blank" rel="noopener">
                <img src="../assets/images/LINE.png" alt="LINEアイコン" />
              </a>
              <a href="https://www.instagram.com/toooozan.kei/" class="sns_icon" target="_blank" rel="noopener">
                <img src="../assets/images/Instagram.png" alt="Instagramアイコン" />
              </a>
              <a href="https://www.tiktok.com/@toooozan.kei" class="sns_icon" target="_blank" rel="noopener">
                <img src="../assets/images/tiktok.png" alt="TikTokアイコン" />
              </a>
            </div>
          </div>
        </nav>
      </transition>
    </nav>
  </header>
</template>
<script>
export default {
  data() {
    return {
      menuOpen: '',
      items: [
        {
          id: 1,
          url: '/engineer',
          name: 'エンジニアとは',
        },
        {
          id: 2,
          url: '/admission-guide',
          name: '学習内容',
        },
        {
          id: 3,
          url: '/graduate',
          name: '実績/卒業生の声',
        },
        {
          id: 4,
          url: '/learning',
          name: '入学案内',
        },
        {
          id: 5,
          url: '/inquiry',
          name: 'お申し込み',
        },
      ],
    }
  },
  methods: {
    btnClick() {
      this.menuOpen = false
    },
  },
}
</script>
<style lang="scss" scoped>
* {
  color: #333;
}

.header {
  align-items: center;
  background-color: rgb(255 255 255);
  border-bottom: 3px solid #fb0d1b;
  box-sizing: border-box;
  display: flex;
  height: 50px;
  left: 0;
  padding-right: 18px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
  &_logo img {
    vertical-align: middle;
    width: 118px;
  }
  .menu-button {
    cursor: pointer;
    display: inline-block;
    height: 14px;
    position: fixed;
    right: 18px;
    top: 18px;
    width: 20px;
    &_bar {
      background: #fb0d1b;
      display: inline-block;
      height: 2px;
      position: absolute;
      transition: all 0.4s;
      width: 100%;
      &:nth-child(1) {
        top: 0;
      }
      &:nth-child(2) {
        top: 6px;
      }
      &:nth-child(3) {
        top: 12px;
      }
    }
  }
  .open {
    .menu-button_bar:nth-child(1) {
      top: 6px;
      transform: rotate(-45deg);
    }
    .menu-button_bar:nth-child(2) {
      opacity: 0;
    }
    .menu-button_bar:nth-child(3) {
      top: 6px;
      transform: rotate(45deg);
    }
  }
  .nav {
    background: #fb0d1bcc;
    height: calc(100vh - 50px);
    overflow-x: scroll;
    padding: 84px 30px 43px;
    position: fixed;
    right: 0;
    top: 50px;
    width: 100vw;
    z-index: -1;
    &_list {
      li {
        margin: auto;
        max-width: 500px;
        &:not(:last-child) {
          border-bottom: solid 1px #fff;
        }
      }
      &_item {
        color: #fff;
        display: block;
        font-size: 18px;
        padding: 15px 30px;
        position: relative;
        text-decoration: none;
        &::after {
          content: '▶︎';
          position: absolute;
          right: 30px;
        }
      }
    }
    .sns {
      margin-top: 60px;
      &_text {
        color: #fff;
        font-size: 12px;
        text-align: center;
      }
      &_flex {
        display: flex;
        justify-content: center;
        margin-top: 10px;
        .sns_icon {
          height: 80px;
          margin: 0 7.5px;
          width: 80px;
          img {
            width: 100%;
          }
        }
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s ease, transform 0.4s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 0.4s ease, transform 0.4s ease;
}

@media screen and (min-width: 796px) {
  .header {
    display: none;
  }
}
</style>

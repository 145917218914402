import { createRouter, createWebHistory } from 'vue-router'
import TopPageView from '../views/TopPageView.vue'
import ErrorView from '../views/ErrorView.vue'
import EngineerView from '../views/EngineerView.vue'

const routes = [
  {
    path: '/',
    name: 'top',
    component: TopPageView,
  },
  {
    path: '/engineer',
    name: 'engineer',
    component: EngineerView,
  },
  {
    path: '/graduate',
    name: 'graduate',
    component: () => import('../views/GraduateView.vue'),
  },
  {
    path: '/admission-guide',
    name: 'admission-guide',
    component: () => import('../views/AdmissionGuide.vue'),
  },
  {
    path: '/inquiry',
    name: 'inquiry',
    component: () => import('../views/InquiryView.vue'),
  },
  {
    path: '/learning',
    name: 'learning',
    component: () => import('../views/LearningContent.vue'),
  },
  {
    path: '/:catchAll(.*)',
    name: 'error',
    component: ErrorView,
  },
]

const scrollBehavior = (to, from, savedPosition) => {
  if (savedPosition) {
    return savedPosition
  } else {
    return { top: 0 }
  }
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior,
})
export default router

<template>
  <HeaderPc />
  <HeaderSp />
  <router-view />
  <FooterComponent />
</template>

<script>
import HeaderPc from '@/components/HeaderPc.vue'
import HeaderSp from '@/components/HeaderSp.vue'
import FooterComponent from '@/components/FooterComponent.vue'

export default {
  components: {
    HeaderPc,
    HeaderSp,
    FooterComponent,
  },
  methods: {
    setMeta(route) {
      if (route.meta.title) {
        let setTitle = route.meta.title
        document.title = setTitle
      }
    },
  },
  mounted() {
    let route = this.$route
    this.setMeta(route)
  },
  watch: {
    $route(route) {
      this.setMeta(route)
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/css/reset.css';
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap');

#app {
  font-family: 'Noto Sans JP', sans-serif;
}
</style>

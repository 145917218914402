<template>
  <div class="notFound">
    <h1 class="notFound-title">お探しのページが見つかりませんでした。</h1>
  </div>
</template>
<style lang="scss" scoped>
.notFound {
  align-items: center;
  display: flex;
  inset: 0;
  justify-content: center;
  margin: auto;
  padding: 280px 0;
  .notFound-title {
    font-size: 40px;
  }
}

@media screen and (max-width: (795px)) {
  .notFound {
    .notFound-title {
      font-size: 25px;
    }
  }
}
</style>

<template>
  <div class="home">
    <div class="top">
      <div class="top-page">
        <div class="image-catchphrase">
          <h1 class="catchphrase-title">最高のエンジニア集団を、みんなで作りませんか？</h1>
        </div>
        <div class="top_image">
          <div class="top_image_item" v-for="top in topes" :key="top.index">
            <img :src="top.image" alt="" class="top-image-engineer" />
          </div>
        </div>
      </div>
      <div class="btn">
        <button class="apply"><a href="/inquiry" class="apply-link">お申し込みはこちら</a></button>
      </div>
    </div>
    <div class="top-contents">
      <div class="engineer">
        <div class="title">
          <h1 class="title-engineer">Engineer</h1>
          <h2 class="sub-title-engineer">エンジニアとは</h2>
        </div>
        <div class="what">
          <div class="contents">
            <div class="contents-engineer" v-for="content in contents" :key="content.index">
              <div class="what_img">
                <img class="engineer-img" :src="content.image" alt="" />
              </div>
              <div class="work">
                <h2 class="work-title">{{ content.title }}</h2>
              </div>
              <p class="work-sentences">{{ content.first_paragraph }}</p>
              <p class="work-sentences">{{ content.second_paragraph }}</p>
              <p class="work-sentences">{{ content.third_paragraph }}</p>
              <p class="work-sentences">{{ content.fourth_paragraph }}</p>
            </div>
          </div>
        </div>
        <div class="catchphrase">
          <div class="catchphrase-title">
            <h1 class="becoming-engineer">
              <span class="best-engineer-group">最高のエンジニア集団を、</span><br />みんなで作りませんか？
            </h1>
          </div>
          <div class="engineer-btn">
            <button class="detail-engineer-btn">
              <a href="/engineer" class="detail-engineer">エンジニアについて詳しく見る</a>
            </button>
          </div>
        </div>
      </div>
      <hr class="line" />
      <div class="curriculum">
        <div class="title-curriculum">
          <h1 class="title">Curriculum</h1>
          <h2 class="sub-title-curriculum">カリキュラム内容</h2>
        </div>
        <div class="programming-languages">
          <div class="language" v-for="programming in programmings" :key="programming.index">
            <div class="language_img">
              <img class="language_image" :src="programming.image" alt="" />
            </div>
            <p class="language-text">{{ programming.explain }}</p>
          </div>
        </div>
        <div class="target">
          <p class="target-text">これらの学習を通して1人でWebサイトを作れるようになる！</p>
        </div>
        <div class="support">
          <div class="support-system">
            <p class="support-title">サポート体制</p>
          </div>
          <div class="howToSupport">
            <p class="support-text" v-for="support in supports" :key="support.index">
              {{ support.text }}
            </p>
          </div>
        </div>
        <div class="curriculum-btn">
          <button class="detail-curriculum-btn">
            <a href="/learning" class="detail-curriculum">カリキュラムについて詳しく見る</a>
          </button>
        </div>
      </div>
      <hr class="line" />
      <div class="voice">
        <div class="title">
          <h1 class="voice-title">Achievement</h1>
          <h2 class="sub-voice-title">実績</h2>
        </div>
        <p class="experience">
          50人が受講して、半年で半数がエンジニアになったカリキュラムこちらのカリキュラムは未経験の方が、
          エンジニアとして稼働できるようになることを目的に構成した内容になっております
        </p>
        <p class="experience-sp">
          <span class="num">50</span>人が受講して、<br />
          <span class="half-year">半年で半数がエンジニアになったカリキュラム</span>
          こちらのカリキュラムは未経験の方が、
          エンジニアとして稼働できるようになることを目的に構成した内容になっております
        </p>
        <p class="study">学んで満足、できるようになって満足というような内容にはなっておりません</p>
        <div class="title">
          <h1 class="interview-title">Interview</h1>
          <h2 class="sub-interview-title">卒業生インタビュー</h2>
        </div>
        <div class="student">
          <div class="graduate" v-for="student in students" :key="student.index">
            <div class="graduate-student">
              <div class="student-contents">
                <img :src="student.image" alt="" class="person" />
                <h2 class="student-name">{{ student.name }}</h2>
              </div>
              <p class="student-interview">{{ student.text }}</p>
            </div>
          </div>
        </div>
        <div class="student-btn">
          <button class="student-curriculum-btn">
            <a href="/graduate" class="student-curriculum">卒業生の声をもっと見る</a>
          </button>
        </div>
      </div>
      <hr class="line" />
      <div class="entrance">
        <div class="title">
          <h1 class="entrance-title">Flow</h1>
          <h2 class="sub-entrance-title">入学の流れ</h2>
        </div>
        <div class="flow-steps">
          <div class="flow" v-for="step in steps" :key="step.index">
            <div class="steps">
              <div class="steps-title">
                <h2 class="step-title">
                  <span class="step">{{ step.step_title }}</span>
                  {{ step.num }}
                </h2>
              </div>
              <p class="text">{{ step.text }}</p>
            </div>
            <div class="triangle">
              <div class="reverse-triangle"></div>
            </div>
          </div>
        </div>
        <div class="flow-entrance-btn">
          <button><a href="/admission-guide" class="detail-entrance">入学の流れについて詳しく見る</a></button>
        </div>
      </div>
      <hr class="line" />
      <div class="engineer-university">
        <div class="text">
          <p class="try">
            まずはお気軽にお申込みください<br />
            無料だし、とりあえずやってみましょう！！！
          </p>
        </div>
        <div class="apply">
          <button class="apply-university-btn">
            <a href="/inquiry" class="apply-university">エンジニア大学に申し込む</a>
          </button>
        </div>
        <div class="sns">
          <div class="contact">
            <p>各種SNSも運営しております！<br />フォロー、いいね！などのご協力をお願いします</p>
          </div>
          <div class="sns-img">
            <a class="sns-account" v-for="icon in icons" :key="icon.id" :href="icon.url" target="_blank">
              <img class="image" :src="icon.sns_button" alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      topes: [
        {
          image: require('../assets/images/top.jpg'),
        },
      ],
      contents: [
        {
          image: require('../assets/images/top_work_1.jpg'),
          title: 'どんな仕事？',
          first_paragraph: 'お客様が抱えるIT課題を解決することがお仕事です！',
          second_paragraph:
            '僕らが日常的に使っているスマートフォン、パソコン等これらの中のものは全てエンジニアが作っています！みなさんが毎日使っているLINEもInstagramも全てエンジニアが作ったものです！',
          third_paragraph:
            'それ以外にも僕らの目には触れない、 銀行など金融機関の大規模なシステムを動かしている のもエンジニアです！',
        },
        {
          image: require('../assets/images/top_work_2.jpg'),
          title: 'どんな生活？',
          first_paragraph: 'エンジニアの年収は今右肩上がりに伸びております',
          second_paragraph:
            '日本の平均年収が400万前後の中、エンジニアの平均年収は500万を超えております！！これから先、IT業界がブロックチェーンやAI、IoTなどの分野でもさらに発展していくことを考えるとこの年収の高水準はこのままキープされるのではないかと考えております',
          third_paragraph:
            'さらにエンジニアのリモート率はかなり高く、統計ではIT業界のリモート率は50%を超えております。',
          fourth_paragraph: 'つまり年収がめっちゃ高くて、時間と場所にとらわれないのがエンジニアという生き方です！',
        },
      ],
      sp_experiences: [
        {
          text: 'こちらのカリキュラムは未経験の方が',
        },
        {
          text: 'エンジニアとして稼働できるようになることを',
        },
        {
          text: '目的に構成した内容になっております',
        },
      ],
      students: [
        {
          image: require('../assets/images/K.E.jpg'),
          name: 'K.Eさん',
          text: '元ネイリストさんです。半年の学習期間を経て、今はJSのフロントエンジニア兼デザイナーとして稼働しております。',
        },
        {
          image: require('../assets/images/Y.K.jpg'),
          name: 'Y.Kさん',
          text: '最初は副業がしたくて、EUの内容を受講されてましたが、エンジニアの魅力にはまって、今では本職で働かれてます！Node.jsのバックエンドの開発に従事されています',
        },
      ],
      steps: [
        {
          step_title: 'STEP',
          num: '1',
          text: 'エンジニア大学のカリキュラムの内容をしっかり確認する',
        },
        {
          step_title: 'STEP',
          num: '2',
          text: '自分がエンジニアになって、自由に働いている未来を想像してニヤニヤする',
        },
        {
          step_title: 'STEP',
          num: '3',
          text: 'こちらのサイトの申し込みフォームを入力する',
        },
      ],
      programmings: [
        {
          image: require('../assets/images/Logo_HTML.png'),
          explain: 'HTMLとはWebページを作成するための言語です',
          languages: 'HTML',
        },
        {
          image: require('../assets/images/Logo_CSS.png'),
          explain: 'CSSとはWebページの見た目を設定する言語です',
          languages: 'CSS',
        },
        {
          image: require('../assets/images/Logo_JS.png'),
          explain: 'JavaScriptとは動的なWebページを作成することの出来る言語です',
          languages: 'JavaScript',
        },
      ],
      supports: [
        {
          text: '24時間質問し放題',
        },
        {
          text: 'プロのレビューを貰える',
        },
      ],
      icons: [
        {
          sns_button: require('../assets/images/top_page-TikTok.png'),
          url: 'https://www.tiktok.com/@toooozan.kei',
        },
        {
          sns_button: require('../assets/images/top_page-Instagram.png'),
          url: 'https://www.instagram.com/toooozan.kei/',
        },
      ],
    }
  },
}
</script>
<style lang="scss" scoped>
.home {
  background-color: #c4bfbf1a;
  text-align: -webkit-center;
}

.top {
  .top-page {
    text-align: -webkit-center;
    .top_image {
      display: flex;
      .top_image_item {
        display: inline-block;
        width: 100%;
        .top-image-engineer {
          background-size: cover;
          display: block;
          height: 660px;
          opacity: 0.8;
          width: 100%;
        }
      }
    }
    .image-catchphrase {
      position: relative;
      z-index: 1;
      .catchphrase-title {
        color: #fff;
        font-size: 46px;
        left: 5%;
        position: absolute;
        right: 5%;
        text-shadow: 0 3px 6px #333333f5;
        top: 302px;
      }
    }
  }
  .btn {
    .apply {
      border-color: #fb0d1b;
      border-radius: 45px;
      bottom: 170px;
      height: 90px;
      margin-bottom: 73px;
      max-width: 100%;
      position: relative;
      white-space: nowrap;
      width: 360px;
      .apply-link {
        color: #fb0d1b;
        font-size: 24px;
        text-decoration: none;
      }
      .apply-link::after {
        content: '▶';
        margin-left: 5%;
      }
    }
  }
}

.top-contents {
  padding: 0 5% 204px;
  .engineer {
    background-color: #fff;
    padding: 91px 0 100px;
    text-align: -webkit-center;
    width: auto;
    .title {
      margin-bottom: 45px;
      width: 700px;
      .title-engineer {
        color: #f20505;
        font-family: Verdana;
        font-size: 60px;
        font-weight: normal;
        text-align: left;
        text-shadow: 0 6px 6px #f20c1a80;
      }
      .sub-title-engineer {
        color: #333;
        font-size: 24px;
        text-align: left;
      }
    }
    .what {
      .what_img {
        .engineer-img {
          height: 450px;
          width: 700px;
        }
      }
      .contents {
        margin-top: 45px;
        white-space: pre-wrap;
        .contents-engineer {
          .work {
            .work-title {
              align-items: center;
              display: flex;
              font-size: 29px;
              justify-content: center;
              margin-bottom: 21px;
              margin-top: 45px;
            }
            .work-title::before,
            .work-title::after {
              border-top: 3px solid #f74141;
              content: '';
              width: 43.03px;
            }
            .work-title::before {
              margin-right: 5.4px;
            }
            .work-title::before {
              margin-left: 5.4px;
            }
          }
          .work-sentences {
            font-size: 23px;
            line-height: 28px;
            margin-bottom: 145px;
            max-width: 597px;
            text-align: initial;
          }
        }
        .contents-engineer:nth-of-type(2) {
          .work {
            .work-title {
              margin-top: 46px;
            }
          }
        }
        .contents-engineer:nth-child(1) {
          .work-sentences {
            margin-bottom: 45px;
          }
        }
        .contents-engineer:nth-child(2) {
          .work-sentences {
            margin-bottom: 45px;
          }
        }
      }
      .contents .contents-engineer:nth-child(2) {
        .what_img {
          margin-top: 131px;
        }
      }
    }
    .catchphrase {
      margin-top: 100px;
      .catchphrase-title {
        h1 {
          font-size: 50px;
          text-align: center;
        }
      }
      .engineer-btn {
        margin-top: 27px;
        text-align: center;
        .detail-engineer-btn {
          background-color: #f74141;
          border: none;
          border-radius: 49px;
          font-size: 40px;
          font-weight: bold;
          height: 98px;
          max-width: 95%;
          white-space: nowrap;
          width: 1103px;
          .detail-engineer {
            color: #fff;
            text-decoration: none;
          }
          .detail-engineer::after {
            content: '▶︎';
            margin-left: 5%;
          }
        }
      }
    }
  }
  .line {
    background-color: #fc0d1b;
    height: 10px;
    margin: 70px 0;
    width: 60px;
  }
  .voice {
    background-color: #fff;
    height: auto;
    padding: 99px 0 100px;
    width: auto;
    .title {
      text-align: left;
      width: 700px;
      .voice-title {
        color: #f20505;
        font-family: Verdana;
        font-size: 60px;
        font-weight: normal;
        text-shadow: 0 6px 6px #f20c1a80;
        width: 700px;
      }
      .sub-voice-title {
        color: #333;
        font-size: 24px;
        width: 390px;
      }
    }
    .title {
      margin-top: 93px;
      .interview-title {
        color: #f20505;
        font-family: Verdana;
        font-size: 60px;
        font-weight: normal;
        text-shadow: 0 6px 6px rgb(242 12 26 / 50%);
        width: 600px;
      }
      .sub-interview-title {
        color: #333;
        font-size: 24px;
        width: 580px;
      }
    }
    .experience {
      font-size: 24px;
      line-height: 25px;
      margin-top: 53px;
      text-align: left;
      width: 703px;
    }
    .experience-sp {
      display: none;
    }
    .study {
      font-size: 24px;
      margin-top: 53px;
      text-align: left;
      width: 697px;
    }
    .student {
      display: flex;
      justify-content: center;
      margin-top: 49px;
      .graduate {
        background-color: #f54343cc;
        border-radius: 15px;
        box-shadow: 3px 3px 6px #f04d4d;
        height: auto;
        margin-right: 60px;
        width: 460px;
        .graduate-student {
          display: block;
          margin: 37px 4px 0;
          .student-contents {
            display: flex;
            justify-content: space-around;
            .person {
              border-radius: 150px;
              height: 233px;
              width: 234px;
            }
            .student-name {
              color: #fff;
              font-size: 25px;
              padding-top: 197px;
              white-space: nowrap;
            }
          }
          .student-interview {
            color: #fff;
            font-size: 24px;
            line-height: 40px;
            margin-bottom: 70px;
            margin-top: 21px;
            max-width: 700px;
            padding: 0 25px;
            text-align: left;
            white-space: pre-wrap;
          }
        }
      }
      .graduate:nth-of-type(2) {
        margin-right: 0;
      }
    }
    .student-btn {
      margin-top: 66px;
      text-align: center;
      .student-curriculum-btn {
        background-color: #fff;
        border: solid 1px #f74141;
        border-radius: 49px;
        font-size: 40px;
        font-weight: bold;
        height: 98px;
        max-width: 100%;
        white-space: nowrap;
        width: 880px;
        .student-curriculum {
          color: #f74141;
          text-decoration: none;
        }
        .student-curriculum::after {
          content: '▶︎';
          margin: 0 0 0 5%;
        }
      }
    }
  }
  .entrance {
    background-color: #fff;
    height: auto;
    padding: 99px 0 100px;
    width: auto;
    .title {
      text-align: left;
      width: 700px;
      .entrance-title {
        color: #f20505;
        font-family: Verdana;
        font-size: 60px;
        font-weight: normal;
        text-shadow: 0 6px 6px #f20c1a80;
        width: 400px;
      }
      .sub-entrance-title {
        color: #333;
        font-size: 24px;
        width: 390px;
      }
    }
    .entrance-text {
      display: none;
    }
    .flow-steps {
      display: block;
      justify-content: center;
      margin: 0 5%;
      .flow {
        display: block;
        justify-content: center;
        left: 1%;
        margin-top: 75px;
        position: relative;
        right: 4%;
        white-space: pre-wrap;
        .steps {
          align-items: center;
          background-color: #f74141;
          display: flex;
          height: 200px;
          margin: 0 3%;
          max-width: 856px;
          padding: 0;
          padding: 0 30px;
          width: auto;
          .steps-title {
            margin-top: 29px;
            word-spacing: -30px;
            h2 {
              background-color: #fff;
              border-radius: 70px;
              font-size: 60px;
              font-weight: normal;
              height: 112px;
              line-height: 110px;
              margin-bottom: 28px;
              margin-right: 35px;
              white-space: nowrap;
              width: 112px;
              word-spacing: -10px;
              .step {
                font-size: 18px;
              }
            }
          }
          .text {
            color: #fff;
            font-size: 24px;
            line-height: unset;
            margin-top: 0;
            text-align: left;
            white-space: pre-wrap;
          }
        }
        .triangle {
          margin-top: 21px;
          text-align: -webkit-center;
          .reverse-triangle {
            border-left: 33px solid transparent;
            border-right: 33px solid transparent;
            border-top: 53px solid #5a5858;
            height: 0;
            margin-top: 15px;
            width: 0;
          }
        }
      }
      .flow:nth-of-type(2) {
        margin: 21px 0 0;
      }
      .flow:nth-of-type(3) {
        margin: 21px 0 0;
        .triangle {
          display: none;
        }
      }
    }
    .flow-entrance-btn {
      margin-top: 84px;
      text-align: center;
      button {
        background-color: #fff;
        border: solid 1px #f74141;
        border-radius: 49px;
        font-size: 40px;
        font-weight: bold;
        height: 98px;
        max-width: 95%;
        width: 1103px;
        .detail-entrance {
          color: #f74141;
          text-decoration: none;
        }
        .detail-entrance::after {
          content: '▶';
          margin-left: 5%;
        }
      }
    }
  }
  .curriculum {
    background-color: #fff;
    padding-bottom: 100px;
    width: auto;
    .title-curriculum {
      padding-top: 100px;
      text-align: left;
      width: 700px;
      .title {
        color: #f20505;
        font-family: Verdana;
        font-size: 60px;
        font-weight: normal;
        text-shadow: 0 6px 6px #f20c1a80;
      }
      .sub-title-curriculum {
        color: #333;
        font-size: 24px;
        width: 600px;
      }
    }
    .programming-languages {
      display: flex;
      justify-content: center;
      margin: 58px 1% 0 10%;
      .language {
        margin-right: 53px;
        .language_img {
          .language_image {
            height: auto;
            max-width: 200px;
          }
        }
        .language-text {
          font-size: 18px;
          margin-top: 13px;
          max-width: 236px;
        }
      }
    }
    .target {
      margin-top: 133px;
      .target-text {
        font-size: 36px;
      }
    }
    .support {
      display: flex;
      justify-content: center;
      margin-top: 83px;
      .support-system {
        background-color: #f74141;
        border-radius: 15px;
        color: #fff;
        font-size: 30px;
        height: 129px;
        width: 266px;
        .support-title {
          margin: 40px 0;
        }
      }
      .howToSupport {
        display: block;
        .support-text {
          font-size: 32px;
          font-weight: bold;
          margin: 12px 0 0 39px;
          text-align: left;
        }
        .support-text::before {
          content: '✔️';
        }
      }
    }
    .curriculum-btn {
      margin-top: 66px;
      text-align: center;
      .detail-curriculum-btn {
        background-color: #fff;
        border: solid 1px #f74141;
        border-radius: 49px;
        font-size: 40px;
        font-weight: bold;
        height: 98px;
        max-width: 100%;
        white-space: nowrap;
        width: 880px;
        .detail-curriculum {
          color: #f74141;
          text-decoration: none;
        }
        .detail-curriculum::after {
          content: '▶︎';
        }
      }
    }
  }
  .engineer-university {
    background-color: #fff;
    height: auto;
    padding-bottom: 100px;
    padding-top: 156px;
    width: auto;
    .text {
      font-size: 32px;
      .try {
        font-weight: bold;
      }
    }
    .apply {
      font-size: 36px;
      margin-top: 55px;
      .apply-university-btn {
        background-color: #f74141;
        border-radius: 49px;
        border-style: none;
        height: 98px;
        max-width: 100%;
        width: 880px;
        .apply-university {
          color: #fff;
          text-decoration: none;
        }
      }
    }
    .sns {
      margin-top: 98px;
      .contact {
        font-size: 28px;
      }
      .sns-img {
        display: flex;
        justify-content: center;
        margin: 39px 21% 0 20%;
        .sns-account {
          .image {
            border-radius: 50%;
            height: 200px;
            max-width: 200px;
            width: 200px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: (795px)) {
  .home {
    background-color: transparent;
    padding: 0;
  }
  .top {
    .top-page {
      .top_image {
        .top_image_item {
          .top-image-engineer {
            height: 550px;
          }
        }
      }
      .image-catchphrase {
        .catchphrase-title {
          font-size: 26px;
          left: 12%;
          top: 202px;
        }
      }
    }
    .btn {
      .apply {
        background-color: #fff;
        bottom: 220px;
        height: 55px;
        width: 220px;
        .apply-link {
          font-size: 18px;
        }
      }
    }
  }
  .top-contents {
    padding: 0;
    .engineer {
      padding: 0;
      .title {
        max-width: 300px;
        .title-engineer {
          font-size: 32px;
          text-align: center;
        }
        .sub-title-engineer {
          font-size: 12px;
          text-align: center;
        }
      }
      .what {
        .contents {
          margin-top: 21px;
          .contents-engineer {
            .what_img {
              .engineer-img {
                height: 200px;
                width: 300px;
              }
            }
            .work {
              .work-title {
                font-size: 18px;
              }
            }
            .work-sentences {
              font-size: 12px;
              line-height: 18px;
              margin-left: 0;
              padding: 0 19%;
              text-align: center;
            }
          }
          .contents-engineer:nth-child(1) {
            .work-sentences {
              margin-bottom: 15px;
            }
          }
          .contents-engineer:nth-child(2) {
            .work-sentences {
              margin-bottom: 15px;
            }
          }
        }
        .contents .contents-engineer:nth-child(2) {
          .what_img {
            margin-top: 71px;
          }
        }
      }
      .catchphrase {
        margin-top: 50px;
        .catchphrase-title {
          display: none;
        }
        .engineer-btn {
          margin-top: 0;
          .detail-engineer-btn {
            font-size: 16px;
            height: 35px;
            width: 300px;
          }
        }
      }
    }
    .curriculum {
      padding-bottom: 0;
      .title-curriculum {
        padding-top: 0;
        text-align: center;
        width: 300px;
        .title {
          font-size: 32px;
        }
        .sub-title-curriculum {
          font-size: 12px;
          width: 300px;
        }
      }
      .programming-languages {
        margin: 33px 7% 0 10%;
        .language {
          margin-right: 25px;
          .language_img {
            .language_image {
              width: 80px;
            }
          }
          .language-text {
            font-size: 10px;
            line-height: 15px;
            max-width: 118px;
            text-align: left;
            transform: scale(0.9);
          }
        }
      }
      .target {
        margin-top: 39px;
        .target-text {
          font-size: 12px;
        }
      }
      .support {
        margin-top: 27px;
        .support-system {
          font-size: 12px;
          height: 54px;
          width: 112px;
          .support-title {
            margin: 20px 0;
          }
        }
        .howToSupport {
          .support-text {
            font-size: 10px;
            margin: 8px 0 0 13px;
          }
        }
      }
      .curriculum-btn {
        .detail-curriculum-btn {
          font-size: 14px;
          height: 35px;
          width: 300px;
        }
      }
    }
    .voice {
      padding: 0;
      .title {
        width: 200px;
        .voice-title {
          font-size: 32px;
          width: 0;
        }
        .sub-voice-title {
          font-size: 12px;
          text-align: center;
          width: 200px;
        }
      }
      .experience {
        display: none;
      }
      .experience-sp {
        display: block;
        font-size: 12px;
        margin-top: 22px;
        max-width: 274px;
        text-align: center;
        .num {
          font-weight: bold;
        }
        .half-year {
          font-weight: bold;
        }
      }
      .study {
        font-size: 12px;
        margin-top: 13px;
        max-width: 274px;
        text-align: center;
      }
      .title {
        margin-top: 42px;
        .interview-title {
          font-size: 32px;
          text-align: center;
          width: 200px;
        }
        .sub-interview-title {
          font-size: 12px;
          text-align: center;
          width: 200px;
        }
      }
      .student {
        margin-top: 42px;
        .graduate {
          margin-right: 10px;
          width: 150px;
          .graduate-student {
            margin: 14px 0 0 4px;
            .student-contents {
              justify-content: stretch;
              margin-left: 10px;
              .person {
                height: 90px;
                width: 90px;
              }
              .student-name {
                font-size: 10px;
                padding-top: 67px;
              }
            }
            .student-interview {
              font-size: 10px;
              line-height: unset;
              margin: 9px 0 17px;
              padding: 0;
              transform: scale(0.8);
            }
          }
        }
      }
      .student-btn {
        margin-top: 40px;
        .student-curriculum-btn {
          font-size: 14px;
          height: 35px;
          white-space: normal;
          width: 300px;
        }
      }
    }
    .entrance {
      padding: 0;
      .title {
        width: 200px;
        .entrance-title {
          font-size: 32px;
          text-align: center;
          width: 200px;
        }
        .sub-entrance-title {
          font-size: 12px;
          text-align: center;
          width: 200px;
        }
      }
      .flow-steps {
        margin: 0;
        .flow {
          left: 0;
          margin-top: 53px;
          right: 0;
          .steps {
            height: 70px;
            padding: 0 15px;
            width: 300px;
            .steps-title {
              h2 {
                font-size: 32px;
                height: 50px;
                line-height: 45px;
                margin-right: 10px;
                width: 50px;
                word-spacing: -6px;
                .step {
                  display: inline-flex;
                  font-size: 10px;
                  transform: scale(0.8);
                }
              }
            }
            .text {
              font-size: 10px;
              white-space: initial;
            }
          }
          .triangle {
            margin-top: 0;
            .reverse-triangle {
              border-left: 20px solid transparent;
              border-right: 20px solid transparent;
              border-top: 30px solid #5a5858;
              margin-top: 10px;
            }
          }
        }
        .flow:nth-of-type(2) {
          margin: 6px 0 0;
        }
        .flow:nth-of-type(3) {
          margin: 6px 0 0;
        }
      }
      .flow-entrance-btn {
        margin-top: 35px;
        button {
          font-size: 14px;
          height: 35px;
          width: 300px;
        }
      }
    }
    .engineer-university {
      padding-top: 0;
      .text {
        font-size: 12px;
      }
      .apply {
        font-size: 16px;
        margin-top: 23px;
        .apply-university-btn {
          height: 37px;
          width: 341px;
        }
      }
      .sns {
        margin-top: 42px;
        .contact {
          font-size: 10px;
          transform: scale(0.9);
        }
        .sns-img {
          margin: 10px 0 0;
          .sns-account {
            margin: 0;
            .image {
              height: 80px;
              width: 80px;
            }
          }
          .sns-account:nth-of-type(1) {
            margin-left: 0;
          }
        }
      }
    }
  }
}
</style>

<template>
  <footer class="contents">
    <div class="footer">
      <div class="info">
        <div class="company">
          <a href="/" class="logo">
            <img class="logo-img" src="../assets/images/logo_footer.png" alt="logo" />
          </a>
        </div>
        <div class="navigation">
          <ul class="menu_list">
            <li class="menu" v-for="nav_menu in menus" :key="nav_menu.id">
              <router-link :to="nav_menu.path" class="link">{{ nav_menu.name }}</router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="sns">
        <div class="sns-image">
          <a v-for="icon in icons" :key="icon.id" :href="icon.url" target="_blank" class="icon-btn">
            <img :src="icon.sns_button" class="sns-account" alt="sns" />
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      menus: [
        {
          name: 'エンジニアとは',
          path: '/engineer',
        },
        {
          name: '学習内容',
          path: '/learning',
        },
        {
          name: '実績/卒業生の声',
          path: '/graduate',
        },
        {
          name: '入学案内',
          path: '/admission-guide',
        },
        {
          name: 'お申し込み',
          path: '/inquiry',
        },
      ],
      icons: [
        {
          sns_button: require('../assets/images/LINE.png'),
          url: 'https://lin.ee/Zns71uO',
        },
        {
          sns_button: require('../assets/images/tiktok.png'),
          url: 'https://www.tiktok.com/@toooozan.kei',
        },
        {
          sns_button: require('../assets/images/Instagram.png'),
          url: 'https://www.instagram.com/toooozan.kei/',
        },
      ],
    }
  },
}
</script>
<style lang="scss" scoped>
.contents {
  background-color: #a2a0a0;
  max-width: 1920px;
  padding: 0 0 35px 20px;
  .footer {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    .info {
      margin-top: 32px;
      .company {
        display: flex;
        margin-left: 25px;
        .logo {
          .logo-img {
            height: 114px;
          }
        }
      }
      .navigation {
        display: flex;
        justify-content: flex-end;
        white-space: nowrap;
        .menu_list {
          display: inline-block;
          margin-top: 23px;
          position: relative;
          .menu {
            display: inline-block;
            margin-left: 6%;
            .link {
              color: #fff;
              font-size: 18px;
              text-decoration: none;
            }
          }
          .menu:nth-child(5) {
            margin-right: 0;
          }
        }
      }
    }
    .sns {
      .sns-image {
        display: flex;
        margin: 75px 35px 0 0;
        .icon-btn {
          display: flex;
          .sns-account {
            height: 100px;
            margin-right: 20px;
            width: 100px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: (1047px)) {
  .sns {
    margin-bottom: 32px;
  }
}

@media screen and (max-width: (848px)) {
  .contents {
    .footer {
      .sns {
        margin-right: 20px;
        .sns-image {
          margin: 75px 0 0;
          .icon-btn {
            .sns-account {
              margin-right: 0;
              max-width: 100px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: (815px)) {
  .contents {
    .footer {
      justify-content: end;
    }
  }
}

@media screen and (max-width: (795px)) {
  .contents {
    padding: 22px 0 0 24px;
    .footer {
      justify-content: space-between;
      .info {
        margin: 0 0 32px;
        .company {
          display: block;
          margin-left: 0;
          .logo {
            .logo-img {
              height: 59px;
            }
          }
        }
        .navigation {
          margin-top: 0;
          .menu_list {
            line-height: 18px;
            margin-top: 16px;
            .menu {
              display: block;
              margin: 0 0 4px;
              text-align: left;
              .link {
                font-size: 9px;
              }
            }
          }
        }
      }
      .sns {
        margin-right: 0;
        .sns-image {
          flex-direction: row-reverse;
          margin: 101px 10px 0 0;
          .icon-btn {
            .sns-account {
              height: 50px;
              margin: 0 10px 0 0;
              width: 50px;
            }
          }
          .icon-btn:nth-child(1) {
            order: 1;
          }
        }
      }
    }
  }
}
</style>

<template>
  <div class="engineer">
    <h1 class="engineer-title">エンジニアとは?</h1>
    <div class="job">
      <h2 class="job-title">どんな仕事？</h2>
      <div class="job-content">
        <div class="job-content-title">
          <div class="job-box" v-for="job in jobs" :key="job.id">
            <h3 class="job-box-title">{{ job.title }}</h3>
            <p class="job-box-text">{{ job.text }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="life">
      <h2 class="life-title">どんな生活？</h2>
      <div class="life-content">
        <div class="life-content-title">
          <div class="life-box">
            <h3 class="life-box-title">{{ life.title }}</h3>
            <p class="life-box-text">{{ life.text }}</p>
          </div>
          <div class="life-salary">
            <dl class="life-salary-head">
              <dt class="life-salary-head-item">年代</dt>
              <dt class="life-salary-head-item">平均年収</dt>
              <dt class="life-salary-head-item">月収</dt>
            </dl>
            <dl class="life-salary-list" v-for="table in tables" :key="table.id">
              <dd class="life-salary-list-item">{{ table.age }}</dd>
              <dd class="life-salary-list-item">{{ table.average }}</dd>
              <dd class="life-salary-list-item">{{ table.monthly }}</dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
    <div class="application">
      <div class="application-content">
        <div claas="application-visual">
          <img class="application-visual-img" src="../assets/images/apply_background.jpg" alt="" />
        </div>
        <button class="application-btn"><a href="/inquiry" class="application-link">お申し込みはこちら</a></button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      jobs: [
        {
          title: '働き方',
          text: '基本的にリモートが多いです。\n成果物が納期に間に合えば、どんなタイミングで仕事をするかはあなたの成果次第です！',
        },
        {
          title: '社会貢献',
          text: '社会を動かすほどの大きなシステムを作っているのも、\n個人が使うようなLINEのようなアプリを作っているのも全てエンジニアです\n\n「こんなのがあったら便利だよね」「こんなのあったら楽しいよね」\n\nそんなものをエンジニアは作っていくことが可能です',
        },
        {
          title: 'どんなものを作っているのか',
          text: 'みなさんがお持ちのスマホ、パソコンの中にあるのは全てエンジニアと\n呼ばれる人たちが作ったものです\n\n大手企業が使う業務システムから、個人がエンタメに使うもの、スマホゲームなど\nエンジニアが作れるものの幅はとても広いです\n\n自分がどの領域に進むかで、作れるものは大きく変わります',
        },
      ],
      life: { title: '年収', text: 'エンジニアは高い給与水準を維持しております' },
      tables: [
        {
          age: '20～24',
          average: '339万円',
          monthly: '25.4万円',
        },
        {
          age: '25～29',
          average: '445万円',
          monthly: '30.6万円',
        },
        {
          age: '30～34',
          average: '533万円',
          monthly: '35.8万円',
        },
        {
          age: '35～39',
          average: '573万円',
          monthly: '38.7万円',
        },
        {
          age: '40～44',
          average: '621万円',
          monthly: '42.1万円',
        },
        {
          age: '45～49',
          average: '651万円',
          monthly: '43.4万円',
        },
        {
          age: '50～54',
          average: '666万円',
          monthly: '44.5万円',
        },
        {
          age: '55～59',
          average: '652万円',
          monthly: '44.4万円',
        },
      ],
    }
  },
}
</script>
<style lang="scss" scoped>
.engineer {
  max-width: 1920px;
  margin: 0 auto;
}

.engineer-title {
  text-align: center;
  position: relative;
}

.engineer-title::after {
  content: 'Engineer';
  font-family: Verdana, sans-serif;
  font-weight: normal;
  position: absolute;
  color: #f20505;
  text-shadow: 0px 6px 6px #f20c1a80;
}

.job {
  max-width: 1200px;
  display: flex;
}

.job-title {
  color: #333333;
  font-weight: 500;
  position: relative;
  height: 100px;
  white-space: nowrap;
}

.job-title::after {
  content: "What's the job";
  font-family: Verdana, sans-serif;
  display: inline-block;
  position: absolute;
}

.job-content {
  flex-grow: 1;
}

.job-box-title {
  display: inline;
  color: #f82531;
  border: 1px solid #f82531;
  border-radius: 10px;
}

.job-box-text {
  margin-top: 30px;
  padding-left: 30px;
  font-size: 18px;
  white-space: pre-wrap;
}

.job-box:nth-of-type(n + 2) {
  margin-top: 50px;
}

.life {
  max-width: 1200px;
  margin: 0 auto;
  padding: 100px 50px 50px 50px;
  display: flex;
}

.life-content {
  flex-grow: 1;
}

.life-title {
  color: #333333;
  font-weight: 500;
  position: relative;
  white-space: nowrap;
}

.life-title::after {
  content: "What's the lifestyle";
  font-size: 16px;
  font-family: Verdana, sans-serif;
  display: inline-block;
  position: absolute;
}

.life-box-title {
  display: inline;
  padding: 13px 30px;
  font-size: 24px;
  color: #f82531;
  border: 1px solid #f82531;
  border-radius: 10px;
}

.life-box-text {
  margin-top: 30px;
  padding-left: 30px;
  font-size: 18px;
  white-space: pre-wrap;
}

.life-salary {
  margin-top: 100px;
  max-width: 600px;
}

.life-salary-head {
  display: flex;
}

.life-salary-head-item {
  padding: 10px;
  text-align: center;
  font-weight: bold;
  border-bottom: solid 1px #333333;
}

.life-salary-list {
  display: flex;
  border-bottom: solid 1px #333333;
}

.life-salary-list:last-of-type {
  border-bottom: none;
}

.life-salary-list-item {
  padding: 10px;
  flex-grow: 1;
  text-align: center;
}

.application-content {
  position: relative;
}

.application-visual-img {
  aspect-ratio: 49/27;
  width: 980px;
  margin: 0 auto;
}

.application-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f74141;
  border: none;
  border-radius: 50px;
  font-weight: bold;
  height: 98px;
  white-space: nowrap;
  max-width: 90%;
}

.application-link {
  color: #ffffff;
  display: block;
  text-decoration: none;
}

.application-link::after {
  content: '▶';
  color: #ffffff;
}

@media screen and (min-width: (796px)) {
  .engineer {
    padding: 300px 0 100px 0;
  }

  .engineer-title {
    font-size: 32px;
  }

  .engineer-title::after {
    font-size: 90px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.3;
  }

  .job {
    margin: 100px auto 0 auto;
    padding: 0 0 100px 50px;
    position: relative;
  }

  .job::after {
    content: '';
    position: absolute;
    border-bottom: 1px solid #333333;
    width: 80%;
    height: 1px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  .job-title {
    font-size: 30px;
  }

  .job-title::after {
    font-size: 16px;
    left: 20px;
    right: 0;
    bottom: 35px;
  }

  .job-box-title {
    padding: 13px 30px;
    font-size: 24px;
  }

  .job-content {
    margin-left: 100px;
  }

  .life {
    position: relative;
  }

  .life::after {
    content: '';
    position: absolute;
    border-bottom: 5px solid #fc0d1b;
    width: 60px;
    height: 5px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  .life-title {
    font-size: 30px;
    height: 100px;
  }

  .life-title::after {
    left: 10px;
    right: 0;
    margin: 0 auto;
    bottom: 35px;
  }

  .life-content {
    margin-left: 100px;
  }

  .life-box-title {
    padding: 13px 30px;
    font-size: 24px;
  }

  .life-salary {
    padding-left: 50px;
  }

  .life-salary-head-item {
    background-color: #cccaca;
    font-size: 24px;
  }

  .life-salary-head-item:nth-of-type(1) {
    flex-grow: 35;
  }

  .life-salary-head-item:nth-of-type(2) {
    flex-grow: 25;
  }

  .life-salary-head-item:nth-of-type(3) {
    flex-grow: 40;
  }

  .life-salary-list-item {
    font-size: 20px;
  }

  .application {
    padding: 100px;
  }

  .application-btn {
    font-size: 36px;
    width: 880px;
  }

  .application-link::after {
    margin-left: 30px;
  }
}

@media screen and (max-width: (795px)) {
  .engineer {
    padding: 200px 0 100px 0;
  }

  .engineer-title {
    font-size: 20px;
  }

  .engineer-title::after {
    font-size: 50px;
    top: -70px;
    left: 0;
    right: 0;
    opacity: 0.75;
  }

  .job {
    flex-direction: column;
    margin: 30px auto 0 auto;
    padding: 30px 30px 50px 30px;
  }

  .job-title {
    padding: 20px;
    height: 70px;
    background-color: #d1cdcd;
    font-size: 20px;
    font-weight: bold;
  }

  .job-title::after {
    top: 30px;
    font-size: 10px;
  }

  .job-content {
    margin-top: 50px;
  }

  .job-box-title {
    padding: 13px 20px;
    font-size: 16px;
  }

  .life {
    flex-direction: column;
    padding: 50px 30px 50px 30px;
  }

  .life-title {
    padding: 20px;
    height: 70px;
    background-color: #d1cdcd;
    font-size: 20px;
    font-weight: bold;
  }

  .life-title::after {
    top: 30px;
    margin: 0 auto;
    font-size: 10px;
  }

  .life-box-title {
    padding: 13px 20px;
    font-size: 16px;
  }

  .life-content {
    margin-top: 50px;
  }

  .life-salary {
    margin: 100px auto 0 auto;
  }

  .life-salary-head-item {
    background-color: #feddde;
    font-size: 18px;
  }

  .life-salary-head-item:nth-of-type(1) {
    flex-grow: 35;
  }

  .life-salary-head-item:nth-of-type(2) {
    flex-grow: 28;
  }

  .life-salary-head-item:nth-of-type(3) {
    flex-grow: 37;
  }

  .life-salary-list-item {
    font-size: 16px;
  }

  .application {
    padding: 50px 30px 0 30px;
  }

  .application-btn {
    font-size: 14px;
    width: 300px;
  }

  .application-link::after {
    margin-left: 10px;
  }
}
</style>

<template>
  <header class="header">
    <div class="header_logo">
      <router-link to="/">
        <img src="../assets/images/logo_header.png" alt="ロゴ" />
      </router-link>
    </div>
    <nav>
      <ul class="header_nav">
        <li v-for="item in items" :key="item.id" class="header_nav_item">
          <router-link :to="item.url">
            {{ item.name }}
          </router-link>
        </li>
      </ul>
    </nav>
  </header>
</template>
<script>
export default {
  data() {
    return {
      items: [
        {
          id: 1,
          url: '/engineer',
          name: 'エンジニアとは',
        },
        {
          id: 2,
          url: '/admission-guide',
          name: '学習内容',
        },
        {
          id: 3,
          url: '/graduate',
          name: '実績/卒業生の声',
        },
        {
          id: 4,
          url: '/learning',
          name: '入学案内',
        },
        {
          id: 5,
          url: '/inquiry',
          name: 'お申し込み',
        },
      ],
    }
  },
}
</script>
<style lang="scss" scoped>
* {
  color: #333;
}

.header {
  align-items: center;
  background-color: rgb(255 255 255);
  border-bottom: 5px solid #f82531;
  box-sizing: border-box;
  display: flex;
  height: 129px;
  justify-content: space-between;
  left: 0;
  padding: 0 30px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
  &_logo img {
    vertical-align: middle;
    width: 295px;
  }
  &_nav {
    display: flex;
    &_item a {
      color: #333;
      font-size: 16px;
      text-decoration: none;
    }
    &_item:not(:first-child) {
      margin-left: 30px;
    }
  }
}

@media screen and (max-width: 915px) {
  .header {
    &_logo img {
      width: 240px;
    }
    &_nav {
      display: flex;
      &_item a {
        font-size: 14px;
      }
      &_item:not(:first-child) {
        margin-left: 25px;
      }
    }
  }
}

@media screen and (max-width: 795px) {
  .header {
    display: none;
  }
}
</style>
